import { createGlobalStyle } from 'styled-components';
import { variables } from 'oddsgate-ds';

const CustomStyles = createGlobalStyle`
  body {
    font-family: var(--font-nichrome);
    overflow: unset !important;
    font-weight: 400;
    
    h1, h2, h3{
      font-weight: 700;
    }
    h6, p{
      font-family: var(--font-epilogue);
    }

    input, textarea{
      font-family: var(--font-epilogue);
    }

    button{
      font-family: var(--font-nichrome);
    }
    input::file-selector-button {
      font-family: var(--font-nichrome) !important;
    }
  }  

  .font-nichrome{
    font-family: var(--font-nichrome);
  }
  .font-epilogue{
    font-family: var(--font-epilogue);
  }

  .pointers-none{
    pointer-events:none;
  }

  .overflow-hidden{
    overflow: hidden;
  }
    
  .group-radius{
    border-radius: 0px ${variables.radius.lg};
  }
  
  .position-sticky{
    top: 0;
  }


  #mouse-dragger{
    position: fixed;
    top: 0;
    left: 0;
    width: 7rem;
    height: 7rem;
    margin-left: -3.5rem;
    margin-top: -3.5rem;
    pointer-events: none;

    border-radius: 100%;
    color: ${variables.colors.secondary50};
    background: ${variables.colors.primary50};
    
    opacity:0;
  
    transform: scale(0.8);
    transition: opacity 0.5s,transform 0.7s;
    
    z-index: 200;

    & span{
      display:block;
      position: absolute;
      top: 50%;
      left:50%;

      text-transform:uppercase;
      text-align:center;

      opacity: 0;
      transition: all 0.3s;

      transform: translate(-50%, -50%);
    }
    
    &.play{
      & span.play{
        opacity: 1;
      }
    }
    &.drag{
      & span.drag{
        opacity: 1;
      }
    }
  }

  .no-cursor{
    cursor: none !important;

    & div{
      cursor: none !important;
    }

    & a{
      cursor: none !important;
    }

    #mouse-dragger{
      opacity: 1;
      transform: scale(1);
    }
  }

  .home-intro{
    position: relative; 
    
    .svg-group {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      z-index: -1;
      transform: translateY(-5dvw);
  
      .svg {
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 70dvw;
        color: ${variables.colors.primary50};
        transform-origin: center top;
        z-index: -1;
        
        &.left-svg{
          left: 50%;
          transform: translateX(-50%) scale(0.6);
        }
        &.right-svg {
          right: 50%;
          transform: translateX(50%) scale(0.6);
        }
  
        & circle {
          fill: currentColor;
        }
      }
    }
  }

  .home-bg{

    & > img{
      opacity: 0.4;
      background-color: lightgray;
  
      mix-blend-mode: overlay;
      filter: blur(12.5px);
    }
  }

  .about-intro{
    border-radius: 100px;
    padding: 2.4rem 3.6rem;
  }

  .title-bg{    
    position: relative;
    display: inline-block;
    vertical-align: top;
    
    &:before{
      content:'';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      padding-top: 80%;

      border-radius: 100%;
      background-color: var(--color-third10);

      transform: translate(-50%,-55%);
      z-index: -1;
    }
  }

  .dotted-row{
    border: 4px dotted ${variables.colors.secondary50};
    border-radius: ${variables.radius.lg};   
  }

  .gradient-circle{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 30%;
      left: 4%;
      width: 35%;
      padding-top: 35%;
      background-image: url('/assets/components/gradient.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      /* opacity: 0.8; */
    }

    &.gradient-careers{
      &:before{

      }
    }
  }
  .careers-cards{
    border-radius: 24px 0;
  }

  /* .rotated-img {
    transform: rotate(-8deg);
  } */
  .grayscale-img{
    background-color: lightgray;

    & img{
      mix-blend-mode: luminosity;
    }
  }

  .bottom-0{
    bottom: 0;
  }
  .left-50{
    left: 50%;
    transform: translateX(-50%);
  }

  .boxed-group{
    border-radius: ${variables.radius.lg} 0px;
    padding: 4rem 2rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    & h5{
      font-family: var(--font-nichrome);
      font-weight: bold;
    }
  }

  .fixed-blocks-title{
    position: sticky !important;
    top:50%;
  }
  .fixed-blocks > div{
    position: sticky;
    top: 10vh;
    height: 80vh;

    display: flex;
    flex-flow: column;
    justify-content: center;

    padding: 5rem 3rem;
    border-radius: 0 48px;
    margin-bottom: 3rem;

    &:after{
      content:'';
      display: block;
      position: absolute;
      left: 2rem;
      bottom: 2rem;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      background-color: ${variables.colors.third50};
    }
  }

  
  .parallax-cards{
    @media only screen and (min-width: ${variables.responsiveMedia}) { 
      overflow: visible !important;
      & > .background-element{
        transform: rotate(-5deg);
      }
      & figure{
        transform: rotate(15deg) translateY(3rem);
      }
    }
  }

  /* .parallax-cards-wrappper{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    
    min-height: 100dvh;
    
    .parallax-cards > div{    
      & > div{
        position: absolute;
        top: 0%;
        left:0;
        z-index: 1;
        
        &:first-child{
          position: relative;
        }
      }
    }
  } */

  .horizontal-section{
    min-height: 100dvh;

    .horizontal-wrapper{
      width: max-content;
      padding-right: 1rem;

      &>div:nth-child(2)>div{
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        gap: 4rem;
        width: max-content;
        min-height: 100dvh;
      }

      & .boxed-group{
        width: 100%;
        max-width: 90dvw;
        margin-bottom: 1.5rem;
        box-shadow: unset;

        &.will-appear{
          opacity: 0;
          transition: all .3s;

          &.appeared{
            opacity: 1;
          }
        }
      }
      & .block-group{
        margin-bottom: 1rem;
      }
      & picture{
        margin-bottom: 1.5rem;
      }
      & .space-nowrap{
        white-space: nowrap;
      }
      & .animated-svg{
        width: 100%;
      }
      & .animated-svg.position-absolute{
        top:auto;
        bottom: -1.75rem;
        max-width:70rem;
        z-index: -1;
      }

      @media only screen and (min-width: ${variables.responsiveMedia}) { 
        padding-right: 35dvw;
        
        & .boxed-group{
          max-width: 38rem;
          margin-bottom: 2.3rem;
        }
        & picture{
          margin-bottom: 2.3rem;
        }
      }
    }
  }

  .careers-gradient{
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 10%;
      background: linear-gradient(180deg, ${variables.colors.primary50} 0%, rgba(252, 49, 81, 0.00) 100%);
    }
  }

  .careers-blocks{
    display:flex;
    align-items: center;
    
    border-radius: 48px 0;
    margin-bottom: 1.5rem;
    height: 100%;
  }
  .careers-bg{
    overflow: visible;
    
    & > .background-element{
      background-size: 50% auto !important;
    }
  }

  .grid-center{
    & > div{
      justify-content: center;
    }
  }

  .svg-background{
    position: absolute;
    top: 20%;
    left: 10%;
    max-width: 35rem;
  }
  

  .about-overlay{
    .background-element + div{
      opacity: 1 !important;
      background-color: transparent !important;
      background: linear-gradient(-180deg, rgba(44,9,41,0.8) 0%, rgba(44,9,41,1) 100%) !important;
    }
  }

  .rounded-section{
    border-top-left-radius: 900px;
    border-top-right-radius: 900px;
  }

  .full-circle{
    display: flex;
    align-items: center;
    padding: 2rem;
    border-radius: ${variables.radius.md};
    

    @media only screen and (min-width: ${variables.responsiveMedia}) { 
      padding: 8rem 7rem;
      border-radius: 100%;
      height: 100%;
    }
  }

  .spaced-scroll > div{
    @media only screen and (min-width: ${variables.responsiveMedia}) { 
      flex-wrap: nowrap;
      
      & > div{
        transform: rotate(-4deg) translateX(-5svw) scale(0.8);

        &:nth-of-type(3),
        &:nth-of-type(4){
          transform: rotate(4deg) translateX(5svw) scale(0.8);
        }
      }
    }
  }

  
  .careers-title{
    @media only screen and (min-width: ${variables.responsiveMedia}) { 
      position: relative;
      left: 20%;
      width: 110%;
      z-index: 1;
    }
  }

  .careers-effectimg{
    transform: scale(0.5);
    transform-origin: left bottom;

    & img{
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }

  .product-cells{
    @media only screen and (min-width: ${variables.responsiveMedia}) { 
      display: flex;
      flex-flow: column;
      justify-content: stretch;

      &> div{
        height: 100%;
      }
    }
  }

  .licenses-list li{
    display: table;
    color: ${variables.colors.secondary50};
    background-color: ${variables.colors.primary50};
    padding: 0.8rem 1.4rem !important;
    border-radius: ${variables.radius.lg};
    margin: 8px 0;

    & p{
      font-family: var(--font-nichrome);
    }
  }

  .prefooter-bg{
    &:before{
      content:'';
      position: absolute;
      bottom: 0;
      left:0;
      width: 100%;
      height: 50%;
      background-color: ${variables.colors.third50};
    }
  }
  .newsletter-block{
    padding: 5rem;
    border-radius: 0 3rem;
    margin-bottom: 6rem;

    & form{
      max-width: 40rem;
    }
  }
  
  @media only screen and (min-width: ${variables.responsiveMedia}) { 
    .colored-grid{
      .block-group{
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        z-index: 1;
      }
    }
  }

  .footer-links{
    display: flex;
    flex-flow: column;
    
    @media only screen and (min-width: ${variables.responsiveMedia}) { 
      flex-flow: row wrap;
    }
  }
  #site-footer{
    border-top: 4px dotted ${variables.colors.primary50};
    
    & a{
      transition: all 0.3s linear;
      &:hover{
        color: ${variables.colors.primary50} !important;
      }
    }
  }


  //structure
  body > main{
    overflow: clip;
  }

  .style-dark{
    color: var(--color-secondary50);
    background-color: var(--color-third50);
  }
  .style-light{
    color: var(--color-third50);
    background-color: var(--color-secondary50);
  }

  .page-loading{
    cursor: wait !important;

    & body {
      opacity: 0.6 !important;
      pointer-events: none !important;
    }
  }


  //animations
  .elements_animated {
    position: relative;
    opacity: 0;
    pointer-events: none;

    will-change: transform, opacity;

    &.top,
    &.top-t-bottom {
      transform: translateY(-80px);

      &.in-viewport {
        transform: translateY(0px);
      }
    }
    &.bottom,
    &.bottom-t-top {
      transform: translateY(80px);

      &.in-viewport {
        transform: translateY(0px);
      }
    }
    &.left,
    &.left-t-right {
      transform: translateX(-80px);

      &.in-viewport {
        transform: translateX(0px);
      }
    }
    &.right,
    &.right-t-left {
      transform: translateX(80px);

      &.in-viewport {
        transform: translateX(0px);
      }
    }

    &.zoom-in {
      transform: scale(0.8);

      &.in-viewport {
        transform: scale(1);
      }
    }

    &.zoom-out {
      transform: scale(1.2);

      &.in-viewport {
        transform: scale(1);
      }
    }

    &.masked {
      opacity: 0;
      transition-delay: 1s;
      transition: opacity 0.175s ease;
    }


    &.in-viewport {
      opacity: 1;
    }

    transition: all 0.4s ease-in-out;
  }

  .splitted{
    white-space: pre;
    white-space: break-spaces;
    
    & .word{
      position:relative;
      display:inline-block;
      overflow:hidden;
      perspective:500px;
    
      & .char{
        display:inline-block!important;
        
        will-change:transform;
        transform-origin:0 50%;
        transition: transform .4s,opacity .4s cubic-bezier(0.130, 0.470, 0.130, 0.980); 
        transition-delay: calc(var(--char-index) * 0.02s);
      }
    }
    
    &.elements_animated:not(.in-viewport){
      & .word{
        & .char{
          opacity:0;
          transform:translateY(120%) rotateX(-25deg);
        }
      }
    }
  }

  .elements_animated_mask {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: var(--color-third50);
    transform-origin: 100% 0;
    z-index: 15;
    transition: transform 1s ease-in-out;

    .in-viewport & {
      transform: scaleX(0);
    }
  }

  //Scrollbars
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    background-color: var(--color-white);
  }
  
  ::-webkit-scrollbar {
    width: 7px;
    height: 2px;
    background-color: var(--color-white);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-primary50);
  }
    

  //Smooth scroll
  html.lenis {
    height: auto;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }

  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }

  .lenis.lenis-stopped,
  .lenis.no-scroll {
    overflow: hidden;
  }

  .lenis.lenis-scrolling iframe {
    pointer-events: none;
  }
` as any;


export default CustomStyles;
