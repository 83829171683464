"use client";

import {
  Button,
  CloseButton,
  Column,
  Flex,
  Heading,
  Icon,
  RichText,
  Row,
} from "oddsgate-ds";
import {
  StyledHamburger,
  StyledHeader,
  StyledMenu,
  StyledMenuOverlay,
  StyledMenuPanel,
  StyledMenuPanelClose,
  StyledMenuPanelSwitch,
  StyledPageTitle,
  StyledSearch,
} from "./Header.theme";
import { createRef, useState } from "react";

import { Language } from "@/lib/helpers/getI18n";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import Link from "next/link";
import Logo from "../Logo/Logo";
import Newsletter from "../Newsletter/Newsletter.component";
import React from "react";
import { removeScroll } from "@/lib/helpers/scrollHelpers";
import { useGlobalContext } from "@/lib/globalContext";
import { usePathname } from 'next/navigation'
import { useSearchParams } from "next/navigation";

const Header = () => {
  const state = useGlobalContext();
  const pathname = usePathname()

  const searchParams = useSearchParams();
  const [menuActive, setMenuActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [panelActive, setPanelActive] = useState(0);


  const headerOnClick = (isActive: boolean, search: boolean) => {
    removeScroll(isActive, state);

    if (search) {
      menuActive && setMenuActive(false);
      setSearchActive(isActive);
    } else {
      searchActive && setSearchActive(false);
      setMenuActive(isActive);
    }
  };

  const searchContainerRef = createRef<HTMLDivElement>();
  // clickOutSideToClose(searchContainerRef, () => headerOnClick(false, true));

  return (
    <>
      <StyledHeader id="masthead">
        <Row wrap="nowrap" align="center">
          <Column sm={12} className="d-flex align-items-center">
            <div className="flex-grow-1 flex-grow-md-0 flex-shrink-md-1 d-flex align-items-center">
              <Logo />
              {state?.pageTitle && (
                <StyledPageTitle tag="span" size="h5" className="font-nichrome color-secondary50 fw-bold">{state.pageTitle}</StyledPageTitle>
              )}
            </div>
            <div className="flex-grow-1">
              <Flex align="flex-start" justify="flex-end" gap="2rem">
                <div className="d-none d-md-inline-flex">
                  <LanguageSwitcher></LanguageSwitcher>
                </div>
                <nav className="d-none d-md-inline-flex">
                  <ul className="d-flex align-items-center justify-content-end" style={{ gap: "1.5rem" }}>
                    {state?.menus &&
                      state?.menus[`primary-menu`]?.map((item) => {
                        return (
                          <li key={`nav_menu_li_${item.id}`}>
                            <Link
                              href={item.url || ""}
                              target={item.target ? item.target as string : undefined}
                              onClick={() => headerOnClick(false, false)}
                            >
                              <Button
                                variant={"secondary"}
                                rightIcon={<Icon icon="icon-arrow-right" />}
                              >
                                {item.title as string}
                              </Button>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </nav>
                <div className="d-inline-flex position-relative">
                  <StyledHamburger
                    variant="secondary"
                    mode="light"
                    $active={menuActive}
                    rightIcon={<Icon icon="icon-arrowUp" />}
                    onClick={() => headerOnClick(!menuActive, false)}
                  >Menu</StyledHamburger>

                  <StyledMenu
                    $active={menuActive}
                    data-lenis-prevent={true}

                  >
                    <div>
                      <ul className="">
                        {state?.menus &&
                          state?.menus[`mobile-menu`]?.map((item) => {
                            return (
                              <li key={`nav_menu_li_${item.id}`} className="d-block mb-5">
                                <Link
                                  key={`nav_menu_li_-${item.id}`}
                                  href={item.url || ""}
                                  target={item.target ? item.target as string : undefined}
                                  onClick={() => headerOnClick(false, false)}
                                >
                                  <Heading
                                    tag="span"
                                    size="h5"
                                    className={`${pathname === (item?.url as string)?.slice(0, -1) ? 'color-primary50' : 'color-third50'} fw-bold text-uppercase`}
                                  >
                                    {item?.title as string}
                                  </Heading>
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                      <StyledMenuPanelSwitch className="d-md-none mt-6" onClick={() => { setPanelActive(1) }}>
                        <Heading tag="span" size="h5" className="color-primary50 text-uppercase fw-bold">
                          {state?.i18n?.languages.filter((x: Language) => x.code === state.lang)[0].name}
                        </Heading>
                        <Icon icon="icon-arrow-right" />
                      </StyledMenuPanelSwitch>

                      <StyledMenuPanel $active={panelActive === 1}>
                        <div>
                          <LanguageSwitcher />
                        </div>
                        <StyledMenuPanelClose onClick={() => { setPanelActive(0) }}>
                          <Icon icon="icon-close" />
                        </StyledMenuPanelClose>
                      </StyledMenuPanel>
                    </div>
                    <div className="d-block d-md-none mt-3 w-100 bg-primary50" style={{ padding: 0 }}>
                      {state?.menus &&
                        state?.menus[`primary-menu`]?.map((item) => {
                          return (
                            <Link
                              key={`mobile_li_${item.id}`}
                              href={item.url || ""}
                              target={item.target ? item.target as string : undefined}
                              onClick={() => headerOnClick(false, false)}
                            >
                              <Button
                                variant={"secondary"}
                                rightIcon={<></>}
                                className="w-100"
                              >
                                {item.title as string}
                              </Button>
                            </Link>
                          );
                        })}
                    </div>
                    <div className="d-none d-md-block bg-primary50 mt-3">
                      <Heading tag="span" size="h5" className="color-secondary50 mb-4">{state?.dict?.custom?.newsletterMenu}</Heading>
                      <Newsletter />
                    </div>
                  </StyledMenu>
                  <StyledMenuOverlay $active={menuActive} onClick={() => headerOnClick(false, false)}></StyledMenuOverlay>
                </div>
              </Flex>
            </div>
          </Column>
        </Row >
      </StyledHeader >
    </>
  );
};

export default Header;
