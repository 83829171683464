import { Button, variables } from 'oddsgate-ds';
import styled, { css } from 'styled-components';

import { ILanguageSwitcher } from './LanguageSwitcher.interface';

export const StyledTrigger = styled(Button) <ILanguageSwitcher>`
  display: none;
  z-index: 6;

  & span {
    transform: none !important;
  }
  & i{
    position: static;
    opacity: 1;
    font-size: 12px;
    transform: none !important;
  }
  
  ${({ $active }) =>
    $active &&
    css`
    color: ${variables.colors.secondary50};
    background-color: ${variables.colors.primary50};

    & i{
      transform: rotate(180deg) !important;
    }
  `}

  @media only screen and (min-width: ${variables.responsiveMedia}) {
    display: inline-flex;
  }
`

export const StyledLanguageSelector = styled.div<ILanguageSwitcher>`
  @media only screen and (min-width: ${variables.responsiveMedia}) {
    position: absolute;
    top: 100%;
    right: 0;

    margin-top: 12px;

    pointer-events: ${props => props.$active ? "auto" : "none"};
    
    z-index: 6;

    &> div{
      padding: 20px;
      background: ${variables.colors.secondary50};
      box-shadow: ${variables.shadows.sm};
      border-radius: ${variables.radius.md};

      opacity: ${props => props.$active ? 1 : 0};
      transform:  ${props => props.$active ? "rotate(0deg) translateY(0)" : "rotate(8deg) translateY(30px)"}; ;
      transition: all 0.35s ease-in-out;
    }
  }
`;
export const StyledOverlay = styled.div<ILanguageSwitcher>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(44, 9, 41, 0.6);
  
  opacity: ${props => props.$active ? 1 : 0};
  pointer-events: ${props => props.$active ? "auto" : "none"};

  z-index: 5;
  transition: all 0.35s ease-in-out;

  @media only screen and (min-width: ${variables.responsiveMedia}) {
    display: block;
  }
`;
